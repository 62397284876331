export interface IBaseTabProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  className?: string;
}

const BaseTab = ({ index, value, className, children }: IBaseTabProps) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      className={`BaseTab ${className}`}
    >
      {value === index && children}
    </div>
  );
};

export default BaseTab;
