import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { logOut } from '../../core/api';
import { useAppDispatch } from '../../state/hooks';
import { logOutUser } from '../../state/actions';
import './Header.scss';

const Header = () => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleLogOut = async () => {
    logOut().then(() => {
      dispatch(logOutUser());
    });
    handleCloseMenu();
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div className='Header'>
      <div className='Header__container'>
        <div className='Header__title'>Dashboard</div>
        <IconButton className='Header__menuButton' onClick={handleOpenMenu}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          <MenuItem onClick={handleLogOut}>Logout</MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default Header;
