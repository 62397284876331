import {
  BookingStatus,
  CustomerReportReason,
  LocationType,
  PricingModel
} from './backend/models';
import { FilterOptionType } from './types';

export const filterOptions: FilterOptionType[] = [
  { group: 'Type', value: 'Video' },
  { group: 'Type', value: 'Booking' },
  { group: 'Status', value: 'New' },
  { group: 'Status', value: 'Approved' },
  { group: 'Status', value: 'Declined' },
  { group: 'Status', value: 'Completed' }
];

export const translateReportReason = (reason: CustomerReportReason) => {
  //   switch (reason) {
  //     case CustomerReportReason.HarrasmentOrBullying:
  //       return 'Harrasment Or Bullying';
  //     case CustomerReportReason.HarmfulDesinformation:
  //       return 'Harmful Desinformation';
  //     case CustomerReportReason.HateSpeech:
  //       return 'Hate Speech';
  //     case CustomerReportReason.ImpersonatingMe:
  //       return 'Impersonating Me';
  //     case CustomerReportReason.NudityOrPornography:
  //       return 'Nudity Or Pornography';
  //     case CustomerReportReason.PrivateInformation:
  //       return 'Private Information';
  //     case CustomerReportReason.SaleOrPromotionOfDrugs:
  //       return 'Sale Or Promotion Of Drugs';
  //     case CustomerReportReason.SaleOrPromotionOfFirearms:
  //       return 'Sale Or Promotion Of Firearms';
  //     case CustomerReportReason.SelfHarm:
  //       return 'Self Harm';
  //     case CustomerReportReason.ViolenceOrPhysicalAbuseThreat:
  //       return 'Violence Or Physical Abuse Threat';
  //     default:
  //       return 'Unknown';
  //   }
  switch (reason) {
    case CustomerReportReason.NUMBER_0:
      return 'Harrasment Or Bullying';
    case CustomerReportReason.NUMBER_1:
      return 'Harmful Desinformation';
    case CustomerReportReason.NUMBER_2:
      return 'Hate Speech';
    case CustomerReportReason.NUMBER_3:
      return 'Impersonating Me';
    case CustomerReportReason.NUMBER_4:
      return 'Nudity Or Pornography';
    case CustomerReportReason.NUMBER_5:
      return 'Private Information';
    case CustomerReportReason.NUMBER_6:
      return 'Sale Or Promotion Of Drugs';
    case CustomerReportReason.NUMBER_7:
      return 'Sale Or Promotion Of Firearms';
    case CustomerReportReason.NUMBER_8:
      return 'Self Harm';
    case CustomerReportReason.NUMBER_9:
      return 'Violence Or Physical Abuse Threat';
    default:
      return 'Unknown';
  }
};

export const translateBookingStatus = (status: BookingStatus) => {
  switch (status) {
    case BookingStatus.NUMBER_0:
      return 'Provider Confirmation Needed';
    case BookingStatus.NUMBER_1:
      return 'Provider Declined';
    case BookingStatus.NUMBER_2:
      return 'Provider Confirmed';
    case BookingStatus.NUMBER_3:
      return 'Customer Canceled';
    case BookingStatus.NUMBER_4:
      return 'Customer Confimation Needed';
    case BookingStatus.NUMBER_5:
      return 'Finished';
    default:
      return 'Unknown';
  }
};

export const translateLocationType = (locationType: LocationType) => {
  switch (locationType) {
    case LocationType.NUMBER_0:
      return 'Online';
    case LocationType.NUMBER_1:
    case LocationType.NUMBER_2:
    case LocationType.NUMBER_3:
      return 'In Person';
    default:
      return 'Unknown';
  }
};

export const translateLocation = (locationType: LocationType) => {
  switch (locationType) {
    case LocationType.NUMBER_0:
      return 'None';
    case LocationType.NUMBER_1:
      return "Customer's Location";
    case LocationType.NUMBER_2:
      return 'My Location';
    case LocationType.NUMBER_3:
      return 'Flexible';
    default:
      return 'Unknown';
  }
};

export const translatePricingModel = (pricingModel: PricingModel) => {
  switch (pricingModel) {
    case PricingModel.NUMBER_0:
      return 'Hourly';
    case PricingModel.NUMBER_1:
      return 'Fixed Price';
    default:
      return 'Unknown';
  }
};
