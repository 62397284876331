import { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './BasePanel.scss';
import { useDebounce } from '../../../hooks/useDebounce';
import { IPanelProps } from '../../../core/types';

const BasePanel = ({
  title,
  isOpen,
  onClose,
  children,
  animated = true
}: IPanelProps) => {
  const debouncedIsOpened = useDebounce(isOpen, 200);
  const isPanelOpen = isOpen || debouncedIsOpened;

  return createPortal(
    <>
      {isPanelOpen && (
        <div
          className={classNames('BasePanel', {
            slideInFromRight: isOpen && animated,
            slideOutToRight: !isOpen && animated
          })}
        >
          <div className='BasePanel__header'>
            <div className='BasePanel__title'>{title}</div>
            <IconButton
              onClick={() => {
                onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className='BasePanel__content'>{children}</div>
        </div>
      )}
    </>,
    document.getElementById('side-panel')
  );
};

export default BasePanel;
