import { UserViewModel } from '../../core/backend/models';
import { ScheduleActionPayload, ScheduleActionType } from '../action-types';

/**
 * Schedules
 */
export const scheduleAction = (
  action: ScheduleActionPayload
): ScheduleActionType => {
  return {
    type: 'addSchedule',
    payload: action
  };
};

export const scheduleActions = (
  actions: ScheduleActionPayload[]
): ScheduleActionType => {
  return {
    type: 'addSchedules',
    payload: actions
  };
};

export const resetSchedule = (): ScheduleActionType => {
  return {
    type: 'reset'
  };
};

/**
 * Authentication
 */

export const signInUser = (user: UserViewModel) => ({
  type: 'signInUser',
  payload: user
});

export const logOutUser = () => ({ type: 'logOutUser' });
