import { UserViewModel } from '../../core/backend/models';
import { AuthUserActionType } from '../action-types';

export type AuthState = { user: UserViewModel | null };

const initialState: AuthState = { user: null };

const authReducer = (
  state: AuthState = initialState,
  action: AuthUserActionType
) => {
  switch (action.type) {
    case 'signInUser':
      return { ...state, user: action.payload };
    case 'logOutUser':
      return { ...state, user: null };
    default:
      return state;
  }
};

export default authReducer;
