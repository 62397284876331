import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import BasePanel from '../BasePanel/BasePanel';
import { IPanelProps, ReportType } from '../../../core/types';
import './GigPanel.scss';
import { useState, useEffect } from 'react';
import DetailsTab from '../../Tabs/DetailsTab/DetailsTab';
import ConversationTab from '../../Tabs/ConversationTab/ConversationTab';
import MessagesTab from '../../Tabs/MessagesTab/MessagesTab';
import { getBookingChat, getChatMessages } from '../../../core/api';
import Loader from '../../Loader/Loader';
import {
  BookingViewModel,
  ChatViewModel,
  MessageViewModel
} from '../../../core/backend/models';

interface IProps extends IPanelProps {
  booking: BookingViewModel;
  onResolve: (id: number, type: ReportType) => React.MouseEventHandler;
}

const GigPanel = ({
  title = 'Gig details',
  booking,
  onResolve,
  ...props
}: IProps) => {
  const [value, setValue] = useState(0);
  const [isProcessing, setIsProcessing] = useState(true);
  const [chat, setChat] = useState<ChatViewModel | null>(null);
  const [conversation, setConversation] = useState<MessageViewModel[] | null>(
    []
  );

  useEffect(() => {
    if (props.isOpen) {
      setValue(0);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (booking && props.isOpen) {
      setIsProcessing(true);
      requestChatMessages(booking.id).then(() => {
        setIsProcessing(false);
      });
    }
  }, [booking]);

  const requestChatMessages = async (bookingId: number) => {
    const chat = await getBookingChat(bookingId);

    setChat(chat);
    setConversation(chat.messages);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <BasePanel title={title} {...props}>
      {!isProcessing && (
        <Tabs
          value={value}
          onChange={handleChange}
          textColor='secondary'
          indicatorColor='secondary'
          variant='fullWidth'
          sx={{ borderBottom: '1px solid #E0E0E0' }}
        >
          <Tab label='Details' id='simple-tab-0' />
          <Tab label='Conversation' id='simple-tab-1' />
          <Tab label='Messages' id='simple-tab-2' />
        </Tabs>
      )}
      <div className='GigPanel__content'>
        {isProcessing ? (
          <Loader adaptToParent />
        ) : (
          <>
            <DetailsTab
              booking={booking}
              value={value}
              index={0}
              onResolve={onResolve}
            />
            <ConversationTab
              type='messages'
              data={conversation}
              value={value}
              index={1}
            />
            <MessagesTab chat={chat} value={value} index={2} />
          </>
        )}
      </div>
    </BasePanel>
  );
};

export default GigPanel;
