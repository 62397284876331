import { useRef } from 'react';
import dayjs from 'dayjs';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Scrollbar from 'react-scrollbars-custom';
import './Conversation.scss';
import { MessageViewModel } from '../../core/backend/models';
import Message from '../Message/Message';

interface IProps {
  data: MessageViewModel[];
  form?: React.ReactNode;
  title?: string;
  onBackClick?: () => void;
  className?: string;
}

const Conversation = ({
  data,
  title,
  onBackClick,
  form,
  className
}: IProps) => {
  const lastDate = useRef<string | null>(null);
  const firstUser = useRef<string | null>(null);

  return (
    <div className='Conversation'>
      {title && (
        <div className='Conversation__navigation'>
          {onBackClick && (
            <IconButton onClick={onBackClick} sx={{ marginRight: '12px' }}>
              <ArrowBackIcon />
            </IconButton>
          )}
          {title}
        </div>
      )}
      <div className={`Conversation__wrapper ${className}`}>
        <Scrollbar
          noScrollX={true}
          thumbYProps={{ className: 'thumbX' }}
          trackYProps={{ className: 'trackX' }}
          height='100%'
          width='100%'
        >
          <div className='Conversation__content'>
            {data.length ? (
              data.map((message, idx) => {
                const currentDate = dayjs(new Date(message.sendOn)).format(
                  'MM.DD.YYYY'
                );

                if (!firstUser.current) firstUser.current = message.senderId;
                const alignTo =
                  firstUser.current === message.senderId ? 'left' : 'right';

                const shouldDisplayDate = lastDate.current !== currentDate;
                if (shouldDisplayDate) lastDate.current = currentDate;

                return (
                  <>
                    {shouldDisplayDate && (
                      <div
                        key={`date-${idx}`}
                        className='Conversation__dateRow'
                      >
                        <div className='Conversation__date'>{currentDate}</div>
                      </div>
                    )}
                    <Message
                      key={`message-${message.id}`}
                      alignTo={alignTo}
                      message={message}
                    />
                  </>
                );
              })
            ) : (
              <div className='Conversation__noMessages'>No messages.</div>
            )}
          </div>
        </Scrollbar>
      </div>
      {form && form}
    </div>
  );
};

export default Conversation;
