import { Redirect, Route, Switch } from 'react-router-dom';
import { RoutesEnum } from './enums';
import { IRoute } from './types';
import Home from '../pages/Home/Home';
import SignIn from '../pages/SignIn/SignIn';
import { useAppSelector, useAppDispatch } from '../state/hooks';
import { AuthState } from '../state/reducers/authReducer';
import { useEffect, useState } from 'react';
import { logOutUser, signInUser } from '../state/actions';
import Loader from '../components/Loader/Loader';
import { getUser } from './api';

const noAuthenticatedRoutes: IRoute[] = [
  {
    path: RoutesEnum.SignIn,
    exact: true,
    component: SignIn
  },
  {
    type: 'redirect',
    path: '*',
    redirectTo: RoutesEnum.SignIn
  }
];

const authenticatedRoutes: IRoute[] = [
  {
    path: RoutesEnum.Home,
    exact: true,
    component: Home
  },
  {
    type: 'redirect',
    path: '*',
    redirectTo: RoutesEnum.Home
  }
];

const Routes = () => {
  const dispatch = useAppDispatch();
  const auth: AuthState = useAppSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const routes: IRoute[] = auth.user
    ? authenticatedRoutes
    : noAuthenticatedRoutes;

  useEffect(() => {
    if (auth.user) return;

    setIsLoading(true);
    getUser()
      .then((res) => {
        dispatch(signInUser(res));
        setIsLoading(false);
      })
      .catch((e) => {
        if (auth.user && e.response.status === 401) {
          dispatch(logOutUser());
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [auth.user]);

  if (isLoading) return <Loader />;

  return (
    <>
      <Switch>
        {routes.map((route) => {
          if (route.type === 'redirect') {
            return (
              <Route
                exact={route.exact}
                path={route.path}
                key={`redirect-${route.path}`}
              >
                <Redirect to={route.redirectTo || RoutesEnum.Home} />
              </Route>
            );
          }
          return <Route key={route.path} {...route} />;
        })}
      </Switch>
    </>
  );
};

export default Routes;
