import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import BasePanel from '../BasePanel/BasePanel';
import { IPanelProps, ReportType } from '../../../core/types';
import './VideoPanel.scss';
import { useState, useEffect } from 'react';
import ConversationTab from '../../Tabs/ConversationTab/ConversationTab';
import VideoDetailsTab from '../../Tabs/VideoDetailsTab/VideoDetailsTab';
import { getVideoComments } from '../../../core/api';
import Loader from '../../Loader/Loader';
import {
  CommentViewModel,
  FileResponseViewModel
} from '../../../core/backend/models';

interface IProps extends IPanelProps {
  video: FileResponseViewModel;
  onViewBookingDetails: (gigId: number) => void;
}

const VideoPanel = ({
  title = 'Video details',
  video,
  onViewBookingDetails,
  ...props
}: IProps) => {
  const [value, setValue] = useState(0);
  const [isProcessing, setIsProcessing] = useState(true);
  const [comments, setComments] = useState<CommentViewModel[] | null>([]);

  useEffect(() => {
    if (props.isOpen) {
      setValue(0);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (video && props.isOpen) {
      setIsProcessing(true);
      requestVideoComments().then(() => {
        setIsProcessing(false);
      });
    }
  }, [video]);

  const requestVideoComments = async () => {
    const _comments = await getVideoComments(video.id, 0, 30);
    setComments(_comments);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <BasePanel title={title} {...props}>
      {!isProcessing && (
        <Tabs
          value={value}
          onChange={handleChange}
          textColor='secondary'
          indicatorColor='secondary'
          variant='fullWidth'
          sx={{ borderBottom: '1px solid #E0E0E0' }}
        >
          <Tab label='Details' id='simple-tab-0' />
          <Tab label='Comments' id='simple-tab-1' />
        </Tabs>
      )}
      <div className='GigPanel__content'>
        {isProcessing ? (
          <Loader adaptToParent />
        ) : (
          <>
            <VideoDetailsTab
              video={video}
              onViewBookingDetails={() => {
                onViewBookingDetails(video.linkedGig.id);
              }}
              value={value}
              index={0}
            />
            <ConversationTab
              type='comments'
              data={comments}
              value={value}
              index={1}
            />
          </>
        )}
      </div>
    </BasePanel>
  );
};

export default VideoPanel;
