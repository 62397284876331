import classNames from 'classnames';
import Chip from '@mui/material/Chip';
import { SxProps } from '@mui/material';

interface IStatusObject {
  [key: string]: { label: string; backColor: string; foreColor: string };
}

interface IProps {
  status: string;
  sx?: SxProps;
}

const Status = ({ status = 'new', sx }: IProps) => {
  const type: IStatusObject = {
    new: { label: 'New', backColor: '#FF6F00', foreColor: '#FFFFFF' },
    completed: {
      label: 'Completed',
      backColor: '#0B79D0',
      foreColor: '#FFFFFF'
    },
    reported: { label: 'Reported', backColor: '#E0E0E0', foreColor: '#000000' },
    declined: { label: 'Declined', backColor: '#E91E63', foreColor: '#FFFFFF' },
    approved: { label: 'Approved', backColor: '#B4FFC4', foreColor: '#000000' }
  };

  return (
    <div className={classNames('Status')}>
      <Chip
        label={type[status].label}
        sx={{
          backgroundColor: `${type[status].backColor}`,
          color: `${type[status].foreColor}`,
          ...sx
        }}
      />
    </div>
  );
};

export default Status;
