import axios, { AxiosResponse } from 'axios';
import {
  BookingViewModel,
  ChatViewModel,
  CommentViewModel,
  FileResponseViewModel,
  MediaFileReportViewModel,
  MessageViewModel,
  UserViewModel
} from './backend/models';
import getGlobal from './globals';

export const appRequest = async (
  url: string,
  requestInfo: RequestInit = {
    method: 'GET',
    credentials: 'include'
  }
) => {
  const requestResponse = await fetch(url, requestInfo);
  const data = await requestResponse.json();

  return data;
};

export function getPublicFile(fileName: string) {
  if (!fileName) return;
  return `${getGlobal(
    'api'
  )}/PublicFile/DownloadPublicFile?filename=${fileName}`;
}

export function getAwsFile(filename: string) {
  return `https://hoopla-bucket.s3.us-west-2.amazonaws.com/${filename}`;
}

export async function signIn(email: string, password: string) {
  return await axios.post(
    `${getGlobal('api')}/Admin/LogIn`,
    {
      email,
      password
    },
    {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
}

export async function logOut() {
  await axios.get(`${getGlobal('api')}/Admin/LogOut`, {
    withCredentials: true
  });
}

export async function getUser() {
  const response: UserViewModel = await appRequest(
    `${getGlobal('api')}/Accounts/Info/Own`
  );

  return response;
}

export async function getMediaFileReports() {
  const response: MediaFileReportViewModel[] = await appRequest(
    `${getGlobal('api')}/Admin/GetMediaFileReports`
  );

  return response;
}

export async function resolveMediaFileReport(
  userId: string,
  mediaFileId: number,
  adminMessage: string = '',
  isBadVideo: boolean
) {
  // const response: AxiosResponse<MediaFileReportViewModel> = await axios.post(
  // `${getGlobal(
  //   'api'
  // )}/Admin/ResolveMediaFileReport?userId=${userId}&mediaFileId=${mediaFileId}&isBadVideo=${isBadVideo}&adminMessage=${adminMessage}`,
  //   {
  //     withCredentials: true
  //   }
  // );

  const response: MediaFileReportViewModel = await appRequest(
    `${getGlobal(
      'api'
    )}/Admin/ResolveMediaFileReport?userId=${userId}&mediaFileId=${mediaFileId}&isBadVideo=${isBadVideo}&adminMessage=${adminMessage}`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded charset=utf-8'
      }
    }
  );
  return response;
}

export async function getBookingReports() {
  const response: BookingViewModel[] = await appRequest(
    `${getGlobal('api')}/Admin/GetBookingReports`
  );

  return response;
}

export async function resolveBookingReport(
  bookingId: number,
  decisionId: number,
  adminMessage: string = ''
) {
  const response: BookingViewModel = await appRequest(
    `${getGlobal(
      'api'
    )}/Admin/ResolveBookingReport?bookingId=${bookingId}&decisionId=${decisionId}&adminMessage=${adminMessage}`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded charset=utf-8'
      }
    }
  );

  return response;
}

export async function getVideos(skip: number, count: number) {
  const response: FileResponseViewModel[] = await appRequest(
    `${getGlobal(
      'api'
    )}/Content/Videos/NoAuthentication?skip=${skip}&take=${count}`
  );

  return response;
}

export async function getVideoComments(
  mediaFileId: number,
  skip: number,
  take: number
) {
  const paging =
    skip !== null && take !== null ? `&skip=${skip}&take=${take}` : '';
  const response: CommentViewModel[] = await appRequest(
    `${getGlobal(
      'api'
    )}/ContentInteraction/GetMediaFileComments?mediaFileId=${mediaFileId}${paging}`
  );

  return response;
}

//Chat
export async function createDirectChat(userId: string) {
  const response: ChatViewModel = await appRequest(
    `${getGlobal('api')}/Admin/CreateDirectChat?userId=${userId}`
  );

  return response;
}

export async function getBookingChat(bookingId: number) {
  const response: ChatViewModel = await appRequest(
    `${getGlobal('api')}/Messenger/GetBookingChat?bookingId=${bookingId}`
  );

  return response;
}

export async function getChatMessages(
  chatId: string,
  skip: number,
  take: number
) {
  const paging =
    skip !== null && take !== null ? `&skip=${skip}&take=${take}` : '';
  const response: MessageViewModel[] = await appRequest(
    `${getGlobal('api')}/Messenger/GetChatMessages?chatId=${chatId}${paging}`
  );

  return response;
}

export async function sendMessageToChat(chatId: string, message: string) {
  const response: AxiosResponse<ChatViewModel> = await axios.post(
    `${getGlobal('api')}/Admin/SendMessageToChat`,
    {
      chatId,
      messageBody: message
    },
    {
      withCredentials: true
    }
  );

  return response.data;
}
