import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Scrollbar from 'react-scrollbars-custom';
import { BookingViewModel } from '../../../core/backend/models';
import BaseTab, { IBaseTabProps } from '../BaseTab/BaseTabs';
import './DetailsTab.scss';
import { ReportType } from '../../../core/types';
import {
  translateLocation,
  translateLocationType,
  translatePricingModel
} from '../../../core/helpers';

interface IProps extends IBaseTabProps {
  booking?: BookingViewModel;
  onResolve: (id: number, type: ReportType) => React.MouseEventHandler;
}

const DetailsTab = ({ booking, onResolve, ...props }: IProps) => {
  return (
    <BaseTab {...props} className='DetailsTab'>
      <div className='DetailsTab__wrapper'>
        <Scrollbar
          noScrollX={true}
          thumbYProps={{ className: 'thumbX' }}
          trackYProps={{ className: 'trackX' }}
          height='100%'
          width='100%'
        >
          <Grid container direction={'column'} rowSpacing={2}>
            <Grid item>
              <TextField
                id='title'
                label='Title'
                variant='standard'
                value={booking.gig.name}
                inputProps={{
                  readOnly: true
                }}
                fullWidth
              />
            </Grid>

            <Grid item>
              <TextField
                id='category'
                label='Category'
                variant='standard'
                value={booking.gig.category.name}
                inputProps={{
                  readOnly: true
                }}
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                id='type'
                label='Type'
                variant='standard'
                value={translateLocationType(booking.gig.locationType)}
                inputProps={{
                  readOnly: true
                }}
                fullWidth
              />
            </Grid>
            {booking.gig.locationType !== 0 && (
              <Grid item>
                <TextField
                  id='location'
                  label='Location'
                  variant='standard'
                  value={translateLocation(booking.gig.locationType)}
                  inputProps={{
                    readOnly: true
                  }}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item container columnSpacing={2}>
              <Grid item xs={6}>
                <TextField
                  id='pricing'
                  label='Pricing model'
                  variant='standard'
                  value={translatePricingModel(booking.gig.pricingModel)}
                  inputProps={{
                    readOnly: true
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth variant='standard'>
                  <InputLabel htmlFor='price'>Price</InputLabel>
                  <Input
                    id='price'
                    value={booking.gig.price}
                    readOnly
                    startAdornment={
                      <InputAdornment position='start'>$</InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item>
              <TextField
                id='description'
                label='Description'
                multiline
                fullWidth
                maxRows={14}
                variant='standard'
                value={booking.gig.description}
                inputProps={{
                  readOnly: true
                }}
              />
            </Grid>

            <Grid item>
              {!booking.reportResolution ? (
                <Button
                  fullWidth
                  variant='contained'
                  sx={{ marginTop: '17px' }}
                  onClick={onResolve(booking.id, 'booking')}
                >
                  Resolve issue
                </Button>
              ) : (
                <div className='DetailsTab__resolved'>Resolved</div>
              )}
            </Grid>
          </Grid>
        </Scrollbar>
      </div>
    </BaseTab>
  );
};

export default DetailsTab;
