import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import { IModalProps } from '../../../core/types';
import './BaseModal.scss';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 1,
  px: 4,
  pb: 3,
  borderRadius: 2
};

const BaseModal = ({
  title = '',
  isOpen,
  onClose,
  onAccept,
  hideBackdrop = false,
  children,
  isProcessing = false
}: IModalProps) => {
  return (
    <Modal hideBackdrop={hideBackdrop} open={isOpen} onClose={onClose}>
      <Box sx={{ ...style, width: 600 }}>
        {title && <h2 className='BaseModal__title'>{title}</h2>}
        {children && <div className='BaseModal__content'>{children}</div>}
        <div className='BaseModal__actionButtons'>
          <Button onClick={onClose} color='secondary' disabled={isProcessing}>
            Cancel
          </Button>
          <Button onClick={onAccept} color='secondary' disabled={isProcessing}>
            {isProcessing ? (
              <CircularProgress size={17} color='secondary' />
            ) : (
              'Confirm'
            )}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default BaseModal;
