import Chip from '@mui/material/Chip';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { getAwsFile } from '../../core/api';
import { CommentViewModel } from '../../core/backend/models';
import { useAppSelector } from '../../state/hooks';
import { AuthState } from '../../state/reducers/authReducer';
import Mentions from '../Mentions/Mentions';
import UserImage from '../UserImage/UserImage';

import './Comment.scss';

interface IProps {
  comment: CommentViewModel;
  alignTo?: 'left' | 'right';
  className?: string;
}

const Comment = ({ comment, alignTo = 'left', className }: IProps) => {
  const auth: AuthState = useAppSelector((state) => state.auth);
  const isPro = comment.user.roles.find((role) => role.name === 'Creator Pro');
  const date = new Date(comment.timeStamp);

  return (
    <div
      className={classNames(`Comment ${className}`, {
        'Comment--left': alignTo === 'left',
        'Comment--right': alignTo === 'right',
        'Comment--hoopla': comment.user.id === auth.user.id
      })}
    >
      <UserImage
        url={getAwsFile(comment.user.image)}
        letter={comment.user.fullName[0]}
        className='Comment__userImage'
      />
      <div className='Comment__bubble'>
        <div className='Comment__author'>
          {comment.user.id === auth.user.id ? 'You' : comment.user.fullName}
          {isPro && <Chip label='PRO' size='small' />}
        </div>
        <div className='Comment__content'>
          <Mentions content={comment.text} taggedUsers={comment.taggedUsers} />
        </div>
      </div>
      <div className='Comment__time'>{dayjs(date).format('h:mma')}</div>
    </div>
  );
};

export default Comment;
