export enum RoutesEnum {
  Home = '/',
  SignIn = '/signin',
  Forbidden = '/forbidden'
}

export enum PricingModel {
  Hourly = 0,
  FixedPrice = 1
}

export enum BookingResolution {
  CustomerIsRight,
  ProviderIsRight,
  MutualAgreement
}

export enum BookingStatus {
  ProviderConfirmationNeeded = 0,
  ProviderDeclined = 1,
  ProviderConfirmed = 2,
  CustomerCanceled = 3,
  CustomerConfimationNeeded = 4,
  Finished = 5
}

export enum LocationType {
  Non,
  CustomersLocation,
  MyLocation,
  Flexible
}

export enum CustomerReportReason {
  HarrasmentOrBullying = 0,
  HarmfulDesinformation = 1,
  HateSpeech = 2,
  ImpersonatingMe = 3,
  NudityOrPornography = 4,
  PrivateInformation = 5,
  SaleOrPromotionOfDrugs = 6,
  SaleOrPromotionOfFirearms = 7,
  SelfHarm = 8,
  ViolenceOrPhysicalAbuseThreat = 9
}

export enum ProviderReportReason {
  NotAvailable,
  ChangeOfPlans,
  DontWantToDoIt,
  ChangePrice,
  HarrasmentOrBullying,
  HarmfulDesinformation,
  HateSpeech,
  ImpersonatingMe,
  NudityOrPornography,
  PrivateInformation,
  SaleOrPromotionOfDrugs,
  SaleOrPromotionOfFirearms,
  SelfHarm,
  ViolenceOrPhysicalAbuseThreat
}
