import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import Video from '../../Video/Video';
import { getAwsFile } from '../../../core/api';
import './VideoRow.scss';
import Status from '../../Status/Status';
import { IReportRow } from '../../../core/types';
import BaseRow, { IBaseRowProps } from '../BaseRow/BaseRow';
import { translateReportReason } from '../../../core/helpers';
import Mentions from '../../Mentions/Mentions';

interface IProps extends IBaseRowProps {
  video: IReportRow;
  onSelect: React.MouseEventHandler;
  onViewDetails: React.MouseEventHandler;
  onAccept: React.MouseEventHandler;
  onDecline: React.MouseEventHandler;
}

const VideoRow = ({
  video,
  selected,
  onViewDetails,
  onSelect,
  onAccept,
  onDecline
}: IProps) => {
  return (
    <BaseRow selected={selected} onClick={onViewDetails}>
      <TableCell padding='checkbox'>
        <Checkbox color='primary' checked={selected} onClick={onSelect} />
      </TableCell>
      <TableCell component='th' scope='row'>
        <Video
          url={getAwsFile(video.mediaFile.awsUrl)}
          coverUrl={getAwsFile(video.mediaFile.thumbnail?.awsUrl)}
        />
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }} sx={{ maxWidth: '350px' }}>
        <Mentions
          textOnly
          content={video.mediaFile.description}
          taggedUsers={video.mediaFile.taggedUsers}
        />
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top', textTransform: 'capitalize' }}
      >
        {video.type}
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ maxWidth: '130px' }}
      >
        {video.reportingUser.fullName}
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }} sx={{ maxWidth: '120px' }}>
        <Status status={video.status} />
        <div className='VideoRow__reportReasons'>
          {translateReportReason(video.reportReason)}
        </div>
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }}>
        {video.isBadVideo === null && (
          <div className='VideoRow__actions'>
            <IconButton onClick={onAccept}>
              <DoneIcon />
            </IconButton>
            <IconButton onClick={onDecline}>
              <ClearIcon />
            </IconButton>
          </div>
        )}
      </TableCell>
    </BaseRow>
  );
};

export default VideoRow;
