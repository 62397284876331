type ConfigType = 'startLocal' | 'startDev' | 'startQa';
// | 'startStage'
// | 'startLive'
// | 'buildDev'
// | 'buildQa'
// | 'buildStage'
// | 'buildLive';

interface iConfigItem {
  baseRoute: string;
  domain: string;
  api: string;
}

type iConfig = {
  [buildKey in ConfigType]: Partial<iConfigItem>;
};

const BASE_CONFIG: iConfigItem = {
  baseRoute: '/',
  domain: '',
  api:
    process.env.REACT_APP_CONFIG === 'startDev'
      ? '/api'
      : 'https://dev.hoopla.com/api'
};

const GLOBALS_CONFIG: iConfig = {
  startLocal: {
    domain: 'http://localhost:3000'
  },
  startDev: {
    domain: 'http://localhost:3000'
  },
  startQa: {
    domain: 'http://localhost:3000'
  }
  // startStage: {
  //   domain: 'http://localhost:3000'
  // },
  // startLive: {
  //   domain: 'http://localhost:3000'
  // },
  // buildDev: {
  //   domain: 'https://coachpro-dev.trustsourcing.com',
  //   api: 'https://coachpro-dev.trustsourcing.com/api'
  // },
  // buildQa: {
  //   domain: 'https://coachpro-qa.trustsourcing.com',
  //   api: 'https://coachpro-qa.trustsourcing.com/api'
  // },
  // buildStage: {
  //   domain: 'https://coachpro-stage.trustsourcing.com',
  //   api: 'https://coachpro-stage.trustsourcing.com/api'
  // },
  // buildLive: {
  //   baseRoute: '/',
  //   domain: 'https://coachpro.club',
  //   api: 'https://coachpro.club/api'
  // }
};

const configName: ConfigType =
  (process.env.REACT_APP_CONFIG as ConfigType) || 'startDev';

export default function getGlobal(key: keyof iConfigItem): any {
  try {
    return { ...BASE_CONFIG, ...GLOBALS_CONFIG[configName] }[key];
  } catch (err) {
    return '';
  }
}
