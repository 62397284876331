import {
  CommentViewModel,
  MessageViewModel
} from '../../../core/backend/models';
import Comments from '../../Comments/Comments';
import Conversation from '../../Conversation/Conversation';
import BaseTab, { IBaseTabProps } from '../BaseTab/BaseTabs';
import './ConversationTab.scss';

interface IProps extends IBaseTabProps {
  data: MessageViewModel[] | CommentViewModel[];
  type: 'messages' | 'comments';
}
const ConversationTab = ({ data, type, ...props }: IProps) => {
  return (
    <BaseTab {...props} className='ConversationTab'>
      {type === 'messages' ? (
        <Conversation data={data as MessageViewModel[]} />
      ) : (
        <Comments data={data as CommentViewModel[]} />
      )}
    </BaseTab>
  );
};

export default ConversationTab;
