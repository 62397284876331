import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import Video from '../../Video/Video';
import { getAwsFile } from '../../../core/api';
import './BookingRow.scss';
import Status from '../../Status/Status';
import { IReportRow } from '../../../core/types';
import BaseRow, { IBaseRowProps } from '../BaseRow/BaseRow';

interface IProps extends IBaseRowProps {
  booking: IReportRow;
  isActive: boolean;
  onViewDetails: React.MouseEventHandler;
}

const BookingRow = ({ booking, isActive, onViewDetails }: IProps) => {
  return (
    <BaseRow selected={isActive}>
      <TableCell padding='checkbox'></TableCell>
      <TableCell component='th' scope='row'>
        <Video
          url={getAwsFile(booking.gig?.gigVideo?.awsUrl)}
          coverUrl={getAwsFile(booking.gig?.gigVideo?.thumbnail?.awsUrl)}
        />
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }} sx={{ maxWidth: '350px' }}>
        {booking.gig.description}
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top', textTransform: 'capitalize' }}
      >
        {booking.type}
      </TableCell>
      <TableCell
        align='center'
        style={{ verticalAlign: 'top' }}
        sx={{ maxWidth: '130px' }}
      >
        {booking.gig.provider.fullName}
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }} sx={{ maxWidth: '120px' }}>
        <Status status='completed' />
        <Status status='reported' sx={{ marginTop: '8px' }} />
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }}>
        <div className='BookingRow__actions'>
          <Button variant='text' onClick={onViewDetails}>
            View details
          </Button>
        </div>
      </TableCell>
    </BaseRow>
  );
};

export default BookingRow;
