import { combineReducers } from '@reduxjs/toolkit';
import scheduleReducer from './scheduleReducer';
import authReducer from './authReducer';

const reducers = combineReducers({
  schedule: scheduleReducer,
  auth: authReducer
});

export default reducers;
