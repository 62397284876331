import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';

interface IProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

const ReportsTableHeader = ({
  onSelectAllClick,
  numSelected,
  rowCount
}: IProps) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>

        <TableCell align='left' padding='none'>
          Content
        </TableCell>
        <TableCell align='center' padding='normal'>
          Description
        </TableCell>
        <TableCell align='center' padding='normal'>
          Type
        </TableCell>
        <TableCell align='center' padding='normal'>
          Created by
        </TableCell>
        <TableCell padding='normal'>Status</TableCell>
        <TableCell padding='normal'>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default ReportsTableHeader;
