import Chip from '@mui/material/Chip';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { getAwsFile } from '../../core/api';
import { MessageViewModel } from '../../core/backend/models';
import { useAppSelector } from '../../state/hooks';
import { AuthState } from '../../state/reducers/authReducer';
import UserImage from '../UserImage/UserImage';
import './Message.scss';

interface IProps {
  message: MessageViewModel;
  alignTo?: 'left' | 'right';
  className?: string;
}

const Message = ({ message, alignTo = 'left', className }: IProps) => {
  const auth: AuthState = useAppSelector((state) => state.auth);
  const isPro = message.sender?.roles?.find(
    (role) => role.name === 'Creator Pro'
  );

  let alignment = alignTo;
  const date = new Date(message.sendOn);

  let letter;
  if (message.sender.id === auth.user.id) {
    alignment = 'right';
    letter = 'H';
  } else {
    letter = message.sender.fullName[0];
  }

  return (
    <div
      className={classNames(`Message ${className} Message--${alignment}`, {
        'Message--hoopla': message.sender.id === auth.user.id
      })}
    >
      <UserImage
        url={getAwsFile(message.sender?.image)}
        letter={letter}
        className='Message__userImage'
      />
      <div className='Message__bubble'>
        <div className='Message__author'>
          {message.sender.id === auth.user.id ? 'You' : message.sender.fullName}
          {isPro && <Chip label='PRO' size='small' />}
        </div>
        <div className='Message__content'>{message.body}</div>
      </div>
      <div className='Message__time'>{dayjs(date).format('h:mma')}</div>
    </div>
  );
};

export default Message;
