import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import Routes from './core/Routes';
import { store } from './state';
import './main.scss';

const App = () => {
  return (
    <Provider store={store}>
      <div className='App'>
        <CssBaseline />
        <Router>
          <Routes />
        </Router>
      </div>
    </Provider>
  );
};

export default App;
