import Scrollbar from 'react-scrollbars-custom';
import dayjs from 'dayjs';
import './Comments.scss';
import { CommentViewModel } from '../../core/backend/models';
import { useRef } from 'react';
import Comment from '../Comment/Comment';

interface IProps {
  data: CommentViewModel[];
  className?: string;
}

const Comments = ({ data, className }: IProps) => {
  const lastDate = useRef<string | null>(null);

  return (
    <div className='Comments'>
      <div className={`Comments__wrapper ${className}`}>
        <Scrollbar
          noScrollX={true}
          thumbYProps={{ className: 'thumbX' }}
          trackYProps={{ className: 'trackX' }}
          height='100%'
          width='100%'
        >
          <div className='Comments__content'>
            {data.length ? (
              data.map((comment, idx) => {
                const currentDate = dayjs(new Date(comment.timeStamp)).format(
                  'MM.DD.YYYY'
                );
                const dateAreNotTheSame = currentDate !== lastDate.current;
                if (dateAreNotTheSame) lastDate.current = currentDate;

                return (
                  <>
                    {dateAreNotTheSame && (
                      <div
                        key={`date-container-${idx}`}
                        className='Comments__dateRow'
                      >
                        <div className='Comments__date'>{currentDate}</div>
                      </div>
                    )}
                    <Comment key={`comment-${comment.id}`} comment={comment} />
                  </>
                );
              })
            ) : (
              <div className='Comments__noMessages'>No comments.</div>
            )}
          </div>
        </Scrollbar>
      </div>
    </div>
  );
};

export default Comments;
