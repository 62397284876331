import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { ReportType } from '../../core/types';
import { ScheduleActionPayload, ScheduleActionType } from '../action-types';

export type ScheduleState = ScheduleActionPayload[];

const initialState: ScheduleState = [];

const scheduleReducer = (
  state: ScheduleState = initialState,
  action: ScheduleActionType
) => {
  switch (action.type) {
    case 'addSchedule':
      return [...state, action.payload];
    case 'addSchedules':
      return [...state, ...(action.payload as ScheduleActionType[])];
    case 'reset':
      return [];
    default:
      return state;
  }
};

export default scheduleReducer;
