import React, { useEffect, useState } from 'react';
import ActionSnackbar from '../../components/ActionSnackbar/ActionSnackbar';
import ActionsToolbar from '../../components/ActionsToolbar/ActionsToolbar';
import GigPanel from '../../components/DetailsPanel/GigPanel/GigPanel';
import VideoPanel from '../../components/DetailsPanel/VideoPanel/VideoPanel';
import Filter from '../../components/Filter/Filter';
import Loader from '../../components/Loader/Loader';
import DeclineVideoModal from '../../components/Modals/DeclineVideoModal/DeclineVideoModal';
import ResolveIssueModal from '../../components/Modals/ResolveIssueModal/ResolveIssueModal';
import BookingRow from '../../components/Tables/BookingRow/BookingRow';
import ReportsTable from '../../components/Tables/ReportsTable/ReportsTable';
import VideoRow from '../../components/Tables/VideoRow/VideoRow';
import {
  getBookingReports,
  getMediaFileReports,
  resolveBookingReport,
  resolveMediaFileReport
} from '../../core/api';
import {
  BookingViewModel,
  FileResponseViewModel
} from '../../core/backend/models';
import { filterOptions } from '../../core/helpers';
import {
  FilterOptionType,
  IMainPanel,
  IMainSnack,
  IModalState,
  IReportRow,
  ReportType
} from '../../core/types';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import { actions } from '../../state';
import { ScheduleActionPayload } from '../../state/action-types';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { AuthState } from '../../state/reducers/authReducer';
import { ScheduleState } from '../../state/reducers/scheduleReducer';
import './Home.scss';

const Home = () => {
  const dispatch = useAppDispatch();
  const [isProcessing, setIsProcessing] = useState(true);
  const [reportPage, setReportPage] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState<FilterOptionType | null>(
    null
  );
  const [reportModal, setReportModal] = useState<IModalState>({
    isOpen: false,
    id: 0,
    type: null
  });
  const schedule: ScheduleState = useAppSelector((state) => state.schedule);
  const auth: AuthState = useAppSelector((state) => state.auth);
  const [panelState, setPanelState] = useState<IMainPanel>({
    isOpen: false,
    target: null,
    type: null,
    relatedRowIndex: -1
  });
  const [snackState, setSnackState] = useState<IMainSnack>({
    isOpen: false,
    message: ''
  });
  const [reports, setReports] = useState<IReportRow[]>([]);
  const [currentReports, setCurrentReports] = useState<IReportRow[]>([]);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  useEffect(() => {
    getReports();
  }, []);

  /**
   * Filter all reports
   */
  useEffect(() => {
    if (selectedFilter) {
      let filteredReports: IReportRow[] = [];
      const filterValue = selectedFilter.value.toLowerCase();

      if (selectedFilter.group === 'Type') {
        filteredReports = reports.filter(
          (report) => report.type === selectedFilter.value.toLowerCase()
        );
      } else if (selectedFilter.group === 'Status') {
        filteredReports = reports.filter(
          (report) => report.status === selectedFilter.value.toLowerCase()
        );
      }

      setCurrentReports(filteredReports);
      setReportPage(0);
      return;
    }

    setCurrentReports(reports);
    setReportPage(0);
  }, [selectedFilter]);

  /**
   * Process all scheduled tasks
   */
  useEffect(() => {
    if (!snackState.isOpen && schedule.length > 0) {
      const requests: any[] = [];
      schedule.forEach((task) => {
        if (task.type === 'video') {
          const mediaFileReport = reports.find(
            (report) => report.id === task.id && report.type === 'video'
          );

          //Resolve Video
          requests.push(
            resolveMediaFileReport(
              mediaFileReport.reportingUserId,
              task.id,
              task.extra,
              task.action === 'declined'
            )
          );
        } else {
          //Resolve Booking
          requests.push(
            resolveBookingReport(task.id, Number(task.action), task.extra)
          );
        }

        Promise.all(requests)
          .then((response) => {
            dispatch(actions.resetSchedule());
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            getReports();
          });
      });
    }
  }, [snackState.isOpen, schedule]);

  const getReports = async () => {
    const mediaFileReports = await getMediaFileReports();
    const bookingReports = await getBookingReports();
    const _reports: IReportRow[] = [];

    mediaFileReports.forEach((video) => {
      _reports.push({
        id: video.mediaFileId,
        type: 'video',
        status:
          video.isBadVideo === null
            ? 'new'
            : video.isBadVideo
            ? 'declined'
            : 'approved',
        ...video
      });
    });

    bookingReports.forEach((booking) => {
      _reports.push({
        id: booking.id,
        type: 'booking',
        status: 'completed',
        ...booking
      });
    });

    setReports(_reports);
    setCurrentReports(_reports);
    setIsProcessing(false);
  };

  /**
   * Table methods
   */
  const updateMediaReportLocally = (
    items: number | number[],
    tempStatus: string
  ) => {
    const itemsToUpdate = Array.isArray(items) ? items : [items];

    const newReports = currentReports.map((report) => {
      if (report.type === 'booking') return report;

      const foundReport = itemsToUpdate.findIndex(
        (itemId) => itemId === report.id
      );

      const newReport = { ...report };

      if (foundReport !== -1) {
        newReport.status = tempStatus;
        newReport.isBadVideo = tempStatus === 'declined';
      }

      return newReport;
    });

    setCurrentReports(newReports);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setReportPage(newPage);
  };

  /**
   * Actions
   */
  const handleSingleVideoStatus =
    (id: number, action: 'approved' | 'declined', message: string = '') =>
    (event: React.SyntheticEvent) => {
      dispatch(
        actions.scheduleAction({ id, type: 'video', action, extra: message })
      );
      updateMediaReportLocally(id, action);
      openSnack(`Video ${action}`);
    };

  const handleAllVideosStatus = (action: 'approved' | 'declined') => () => {
    const approvedItems: ScheduleActionPayload[] = selectedItems.map(
      (itemId) => ({
        id: itemId,
        type: 'video',
        action
      })
    );

    dispatch(actions.scheduleActions(approvedItems));
    updateMediaReportLocally(selectedItems, action);
    openSnack(`Videos ${action}`);
    setSelectedItems([]);
  };

  const handleSelectionChange = (selectedRows: number[]) => {
    setSelectedItems(selectedRows);
  };

  const confirmVideoDeclination = (message: string) => {
    handleSingleVideoStatus(reportModal.id, 'declined', message);
    closeReportModal();
  };

  const confirmResolveIssue = (decisionId: number, message: string) => {
    dispatch(
      actions.scheduleAction({
        id: reportModal.id,
        type: 'booking',
        action: decisionId,
        extra: message
      })
    );

    closeReportModal();
    openSnack('Booking resolved');
  };

  /**
   * Side Panel
   */
  const handleViewBooking =
    (booking: BookingViewModel, relatedRowIndex: number = -1) =>
    () => {
      if (relatedRowIndex === -1) {
        relatedRowIndex = reports.findIndex(
          (report) => report.id === booking.id && report.type === 'booking'
        );
      }

      setPanelState({
        isOpen: true,
        target: booking,
        type: 'booking',
        relatedRowIndex
      });
    };

  const handleViewVideo =
    (video: FileResponseViewModel, relatedRowIndex: number) =>
    (e: React.SyntheticEvent) => {
      setPanelState({
        isOpen: true,
        target: video,
        type: 'video',
        relatedRowIndex
      });
    };

  const closePanel = () => {
    setPanelState({ ...panelState, isOpen: false, relatedRowIndex: -1 });
  };

  const getGigBooking = (gigId: number) => {
    const booking = reports.find(
      (report) => report.gig.id === gigId && report.type === 'booking'
    );

    if (booking) {
      handleViewBooking(booking)();
    }
  };

  /**
   * Modal Methods
   */
  const handleShowReportModal =
    (id: number, type: ReportType) => (event: React.SyntheticEvent) => {
      setReportModal({ isOpen: true, id, type: type });
    };

  const closeReportModal = () => {
    setReportModal({ isOpen: false, id: 0, type: null });
  };

  /**
   * Snack
   */
  const openSnack = (message: string) => {
    setSnackState({ isOpen: true, message });
  };

  const closeSnack = (e?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackState({ isOpen: false, message: '' });
  };

  const handleSnackUndo = () => {
    dispatch(actions.resetSchedule());
    setCurrentReports(reports);
    closeSnack();
  };

  if (isProcessing) return <Loader />;

  return (
    <MainLayout>
      <div className='Home'>
        <Filter
          options={filterOptions}
          value={selectedFilter}
          onChange={(value: FilterOptionType) => {
            setSelectedFilter(value);
          }}
        />
        <div className='Home__content'>
          <ReportsTable
            rows={currentReports}
            page={reportPage}
            onPageChange={handleChangePage}
            selectedRows={selectedItems}
            onSelectionChange={handleSelectionChange}
            renderItem={(row, index, { isSelected, handleSelectRow }) => {
              return row.type === 'booking' ? (
                <BookingRow
                  key={`booking-row-${index}`}
                  booking={row}
                  isActive={panelState.relatedRowIndex === index}
                  onViewDetails={handleViewBooking(row, index)}
                />
              ) : (
                <VideoRow
                  key={`video-row-${index}`}
                  video={row}
                  selected={isSelected}
                  onSelect={handleSelectRow(row.mediaFileId)}
                  onViewDetails={handleViewVideo(row.mediaFile, index)}
                  onAccept={handleSingleVideoStatus(
                    row.mediaFileId,
                    'approved'
                  )}
                  onDecline={handleShowReportModal(row.mediaFileId, 'video')}
                />
              );
            }}
          />
        </div>
      </div>
      {selectedItems.length > 0 && (
        <ActionsToolbar
          selectedItems={selectedItems.length}
          onApproveAll={handleAllVideosStatus('approved')}
          onDeclineAll={handleAllVideosStatus('declined')}
        />
      )}
      <GigPanel
        isOpen={panelState.isOpen && panelState.type === 'booking'}
        booking={panelState.target}
        title='Gig Details'
        onResolve={handleShowReportModal}
        onClose={closePanel}
      />
      <VideoPanel
        title='Video details'
        isOpen={panelState.isOpen && panelState.type === 'video'}
        video={panelState.target}
        onClose={closePanel}
        onViewBookingDetails={getGigBooking}
      />
      <ActionSnackbar
        open={snackState.isOpen}
        message={snackState.message}
        onClose={closeSnack}
        onAction={handleSnackUndo}
      />
      <DeclineVideoModal
        isOpen={reportModal.isOpen && reportModal.type === 'video'}
        onClose={closeReportModal}
        onAccept={confirmVideoDeclination}
      />
      <ResolveIssueModal
        isOpen={reportModal.isOpen && reportModal.type === 'booking'}
        onAccept={confirmResolveIssue}
        onClose={closeReportModal}
      />
    </MainLayout>
  );
};

export default Home;
