import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import ReportsTableHeader from './ReportsTableHeader';
import { IReportRow } from '../../../core/types';

interface IProps {
  rows: IReportRow[];
  selectedRows: number[];
  onSelectionChange: (selectedRows: number[]) => void;
  renderItem: (
    item: IReportRow,
    index: number,
    helpers: {
      handleSelectRow: (id: number) => React.MouseEventHandler;
      isSelected: boolean;
    }
  ) => React.ReactNode;
  page: number;
  onPageChange: (e: unknown, newPage: number) => void;
}

const ReportsTable = ({
  rows,
  selectedRows,
  onSelectionChange,
  page,
  onPageChange,
  renderItem
}: IProps) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: number[] = rows
        .filter((row) => row.type === 'video' && row.isBadVideo === null)
        .map((item) => item.mediaFileId);

      onSelectionChange(newSelecteds);
      return;
    }

    onSelectionChange([]);
  };

  const handleSelectRow =
    (id: number) => (event: React.MouseEvent<unknown>) => {
      event.stopPropagation();

      const selectedIndex = selectedRows.findIndex(
        (selectedId) => selectedId === id
      );
      let newSelected: number[] = [];

      if (selectedIndex === -1) {
        newSelected = [...selectedRows, id];
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedRows.slice(1));
      } else if (selectedIndex === selectedRows.length - 1) {
        newSelected = newSelected.concat(selectedRows.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedRows.slice(0, selectedIndex),
          selectedRows.slice(selectedIndex + 1)
        );
      }

      onSelectionChange(newSelected);
    };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onPageChange('', 0);
  };

  const isSelected = (id: number) =>
    selectedRows.findIndex((selectedId) => selectedId === id) !== -1;

  const videosCount = useMemo(
    () =>
      rows.filter((row) => row.type === 'video' && row.isBadVideo === null)
        .length,
    [rows]
  );

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size='medium'>
            <ReportsTableHeader
              numSelected={selectedRows.length}
              onSelectAllClick={handleSelectAll}
              rowCount={videosCount}
            />
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) =>
                  renderItem(row, index, {
                    handleSelectRow,
                    isSelected: isSelected(row.mediaFileId)
                  })
                )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 85 * emptyRows
                  }}
                >
                  <TableCell colSpan={12} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default ReportsTable;
