import TextField from '@mui/material/TextField';
import { useCallback, useState } from 'react';
import { IModalProps } from '../../../core/types';
import BaseModal from '../BaseModal/BaseModal';
import './DeclineVideoModal.scss';

const DeclineVideoModal = ({ onAccept, ...props }: IModalProps) => {
  const [message, setMessage] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.currentTarget.value);
  };

  const hanldeOnAccept = useCallback(() => {
    onAccept(message);
  }, [message]);

  return (
    <BaseModal title='Decline video' onAccept={hanldeOnAccept} {...props}>
      <div className='DeclineVideoModal__description'>
        Briefly describe why video was declined
      </div>

      <TextField
        id='reason'
        label='Reason'
        variant='standard'
        value={message}
        onChange={handleChange}
        fullWidth
      />
    </BaseModal>
  );
};

export default DeclineVideoModal;
