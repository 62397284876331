import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './ActionSnackbar.scss';

interface IProps {
  message: string;
  open: boolean;
  onClose: (e: React.SyntheticEvent | Event, reason?: string) => void;
  onAction: () => void;
}

const ActionSnackbar = ({ message, open, onClose, onAction }: IProps) => {
  return (
    <div className='ActionSnackbar'>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={onClose}
        message={message}
        action={
          <>
            <Button color='secondary' size='small' onClick={onAction}>
              UNDO
            </Button>
            <IconButton
              size='small'
              aria-label='close'
              color='inherit'
              onClick={onClose}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </>
        }
      />
    </div>
  );
};

export default ActionSnackbar;
