import BaseTab, { IBaseTabProps } from '../BaseTab/BaseTabs';
import './MessagesTab.scss';
import Messages from '../../Messages/Messages';
import { useEffect, useRef, useState } from 'react';
import MessagesRow from '../../MessagesRow/MessagesRow';
import Conversation from '../../Conversation/Conversation';
import {
  UserViewModel,
  MessageViewModel,
  ChatViewModel
} from '../../../core/backend/models';
import ReplyForm from '../../ReplyForm/ReplyForm';
import { createDirectChat } from '../../../core/api';
import ChatSocket from '../../../core/classes/signal/chat-socket';

interface IProps extends IBaseTabProps {
  chat: ChatViewModel;
}

const MessagesTab = ({ chat, ...props }: IProps) => {
  const [conversationUser, setConversationUser] =
    useState<UserViewModel | null>(null);
  const [conversation, setConversation] = useState<MessageViewModel[]>([]);
  const [currentChat, setCurrentChat] = useState<ChatViewModel | null>(null);
  const liveChat = useRef<ChatSocket | null>(null);

  useEffect(() => {
    //User active - Start socket connection
    if (conversationUser) {
      connectToChat().catch((e) => {
        alert(
          'Something went wrong trying to connect to the hub. Please refresh the page and try again.'
        );
      });
      return;
    }

    //If not user active and isConnected - Disconnect from socket
    if (liveChat.current?.isConnected) disconnectFromChat();
  }, [conversationUser]);

  const connectToChat = async () => {
    try {
      //Get saved chat
      const chat = await createDirectChat(conversationUser.id);
      setCurrentChat(chat);
      setConversation(chat.messages);

      //Start live chat
      const socket = new ChatSocket()
        .setChat(chat)
        .whenReceivingMessage((message: MessageViewModel) => {
          console.log(message);
          setConversation([...conversation, message]);
        });

      await socket.init();
      liveChat.current = socket;
    } catch (e) {
      console.log(e);
    }
  };

  const disconnectFromChat = async () => {
    await liveChat.current.stop();
    liveChat.current = null;
  };

  const handleSendMessage = async (values: any) => {
    if (!chat || !liveChat.current.isConnected) {
      return;
    }

    const message: MessageViewModel = await liveChat.current.call(
      'SendMessage',
      { chatId: currentChat.id, messageBody: values.message }
    );
  };

  const handleCloseChat = () => {
    setConversationUser(null);
    setConversation([]);
    setCurrentChat(null);
  };

  return (
    <BaseTab {...props} className='MessagesTab'>
      {!conversationUser ? (
        <Messages
          data={chat.members}
          renderItem={(user, idx) => (
            <MessagesRow
              key={`conversation-${idx}`}
              user={user}
              onPress={() => {
                setConversationUser(user);
              }}
            />
          )}
        />
      ) : (
        <Conversation
          className='MessagesTab__conversationWrapper'
          data={conversation}
          title={conversationUser.fullName ?? ''}
          onBackClick={handleCloseChat}
          form={
            <ReplyForm
              isLoading={false}
              placeholder='Send your message'
              onSubmit={handleSendMessage}
            />
          }
        />
      )}
    </BaseTab>
  );
};

export default MessagesTab;
