import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Scrollbar from 'react-scrollbars-custom';
import BaseTab, { IBaseTabProps } from '../BaseTab/BaseTabs';
import './VideoDetailsTab.scss';
import Video from '../../Video/Video';
import { getAwsFile } from '../../../core/api';
import { FileResponseViewModel } from '../../../core/backend/models';

interface IProps extends IBaseTabProps {
  video?: FileResponseViewModel;
  onViewBookingDetails?: () => void;
}

const VideoDetailsTab = ({ video, onViewBookingDetails, ...props }: IProps) => {
  return (
    <BaseTab {...props} className='VideoDetailsTab'>
      <div className='VideoDetailsTab__wrapper'>
        <Scrollbar
          noScrollX={true}
          thumbYProps={{ className: 'thumbX' }}
          trackYProps={{ className: 'trackX' }}
          height='100%'
          width='100%'
        >
          <Grid container direction={'column'} rowSpacing={2}>
            <Grid item>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Video
                  url={getAwsFile(video.awsUrl)}
                  coverUrl={getAwsFile(video.thumbnail?.awsUrl)}
                  size='large'
                />
              </div>
            </Grid>
            <Grid item>
              <TextField
                id='description'
                label='Description'
                multiline
                fullWidth
                maxRows={14}
                variant='standard'
                value={video.description?.trim()}
                inputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item>
              <FormControl fullWidth variant='standard'>
                <InputLabel htmlFor='price'>Gig title</InputLabel>
                <Input
                  id='name'
                  value={video.linkedGig?.name}
                  // startAdornment={
                  //   <InputAdornment position='end'>
                  //     <Button variant='text' onClick={onViewBookingDetails}>
                  //       Video details
                  //     </Button>
                  //   </InputAdornment>
                  // }
                  readOnly
                  fullWidth
                />
              </FormControl>
            </Grid>
          </Grid>
        </Scrollbar>
      </div>
    </BaseTab>
  );
};

export default VideoDetailsTab;
